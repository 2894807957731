.menu {
  position: fixed;
  width: 100%;
  background-color: white;
  box-shadow: 0px 2px 8px 1px rgba(202, 202, 202, 0.5);
  z-index: 3;
}

.menu__logo {
  width: 10em;
  margin: 1em;
}

.menu__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.menu__options {
  font-weight: 300;
  font-size: 0.85rem;
}

.menu__item {
  padding: 0 2em;
  text-decoration: none;
  color: #6b6b6b;
}

.menu__item--selected{
  font-weight: 600;
  color: #caab88;
}

.menu__item:hover {
  font-weight: 600;
  color: #caab88;
}

/*Mobile Menu*/
.mobile-menu {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  height: 2em;
  width: 100%;
  padding: 1em;
  background-color: white;
  box-shadow: 0px 2px 8px 1px rgba(202, 202, 202, 0.5);
  z-index: 3;
}

.mobile-menu button {
  border: none;
  padding-right: 3em;
  background-color: white;
  cursor: pointer;
}

.mobile-menu__options {
  position: absolute;
  text-align: center;
  right: 0;
  width: 100%;
  margin-top: 0.5em;
  padding: 2em 0;
  background-color: rgb(255, 255, 255);
}


.mobile-menu__item {
  display: block;
  padding: 1em 0;
  font-weight: 300;
  text-decoration: none;
  color: #6b6b6b;
  cursor: pointer;
}

.mobile-menu__logo {
  width: 8em;
}

@media only screen and (min-width: 1800px) {
  .menu {
    width: auto;
    right: 20%;
    left: 20%;
  }
}