body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  color: #333333;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 120px; /* height of sticky header */
}

@media only screen and (min-width: 1800px) {
  .app{
    margin: 0 20%;
  }
}
