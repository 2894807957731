.top-bar {
  display: flex;
  justify-content: flex-end;
  padding: 0.8em 2em;
  background-color: rgb(238, 238, 238);
}

.top-bar__element {
  display: flex;
  align-items: center;
  margin: 0 1.5em;
  font-size: small;
  font-weight: 300;
  color: grey;
}

.top-bar__contact-icon{
  margin-right: 0.8em;
}

.top-bar__icon {
  color: #3e5ea3;
  margin-left: 0.8em;
}

.top-bar__icon:hover {
  cursor: pointer;
  opacity: 0.6;
}

