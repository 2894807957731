.home__slide {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: right;
  background-size: cover;
  background-position: center;
  color: white;
  padding: 2em;
  height: 100vh;
}

.home__slide-one {
  background-image: url("../../resources/assets/landscapePictures/cascais.jpg");
}

.home__slide-two {
  background-image: url("https://media-manager.noticiasaominuto.com/1920/1581064156/naom_5ac618554622f.jpg?crop_params=eyJsYW5kc2NhcGUiOnsiY3JvcFdpZHRoIjozNzQ0LCJjcm9wSGVpZ2h0IjoyMTA1LCJjcm9wWCI6MTE2LCJjcm9wWSI6MjY5fX0=");
}

.home__slogan {
  font-family: "Times New Roman", Times, serif;
  margin: 0;
  font-size: 1.6rem;
}

