.footer-container{
    /* position: fixed;
    bottom: 0;
    width: 100%; */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #CAAB88;
    color: white;
}

.footer-container h6{
    margin: 0.5rem;
    font-weight: 400;
}