.addresses {
  padding: 3em;
  background-color: rgb(245, 245, 245);
}

.addresses h1 {
  text-align: center;
  font-family: "Times New Roman", Times, serif;
  color: #caab88;
  margin-bottom: 2em;
}

.addresses__text-information {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.addresses__column {
  height: 12em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.addresses__text-information h5 {
  font-weight: 300;
  margin: 0.5rem 0;
  line-height: 1.5rem;
}

.addresses__label {
  font-weight: 600;
  font-size: 0.8rem;
  margin: 0;
  padding: 0;
}

.addresses__social-icon{
  color: rgb(56, 99, 163);
  margin: 0;
  cursor: pointer;
}

.addresses__social-icon:hover{
  opacity: 0.6;
}

.addresses__instagram-icon{
margin-left: 0.6em;
}

.addresses__map {
  width: 50%;
}

.addresses__map img {
  width: 100%;
}
.addresses__map img:hover {
  box-shadow: 0px 2px 8px 1px rgba(202, 202, 202, 0.5);
}


@media only screen and (max-width: 1000px) {
  #address {
    padding: 3rem 1rem;
  }

  .addresses__column {
    width: 100%;
    height: 100%;
    margin: 0.5rem 0;
    padding: 2em 1em;
    text-align: center;
    border: 1px solid rgb(238, 238, 238);
    box-shadow: 0px 2px 8px 1px rgba(202, 202, 202, 0.5);
  }

  .addresses__map  {
    display: none;
  }

}
