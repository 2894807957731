.services {
  padding: 3em 0;
}

.services-cards {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.services-cards__title{
  text-align: center;
  font-family: "Times New Roman", Times, serif;
  color: #caab88;
}

.services-cards__text {
  text-align: center;
  font-weight: 600;
  font-size: 0.8rem;
  margin-top: 2em;
  line-height: 1.2em;
}

@media only screen and (max-width: 800px) {
  .services {
    padding: 3em 1em;
  }
  
}
