.about-us {
  text-align: center;
  padding: 3em 5em;
  background-color: rgb(245, 245, 245);
}

.about-us__title {
  color: #caab88;
  margin: 0;
  font-family: "Times New Roman", Times, serif;
}

.about-us__text {
  font-size: 0.8rem;
  line-height: 1.8em;
}

.about-us__images {
  margin-top: 3rem;
  margin-bottom: 1em;
}

.about-us__image {
  width: 85%;
  margin: auto;
}

.about-us__images h5 {
  margin: 0.6em 0 0.3em 0;
  font-weight: 500;
}

.about-us__images h6 {
  margin: 0;
}
.about-us__image h6 span {
  font-style: italic;
  margin-top: 0.5rem;
}

.about-us__partners-title {
  border-top: 1px solid rgb(184, 184, 184);
  padding: 2em 0 0 0;
  margin: 0;
  font-weight: 400;
}

.about-us__brasil-partner-title {
  margin: 0.5em 0;
  font-weight: 600;
}

.about-us__brasil-partner-role {
  margin: 0.3em 0;
  font-weight: 600;
}
.about-us__brasil-partner-location {
  margin: 0.3em 0;
  font-weight: 600;
}

@media only screen and (max-width: 1200px) {
  .about-us {
    padding: 4em 1em;
  }
}

@media only screen and (max-width: 600px) {
  .image-container h5 {
    font-size: 0.6rem;
  }
  .about-us__images {
    margin-bottom: 2.5em;
  }
}
