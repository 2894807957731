.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 19%;
  margin: 0.2% 0;
  padding: 0.2rem;
  cursor: pointer;
}

.card:hover {
  box-shadow: 0px 1px 4px 3px rgba(0, 0, 0, 0.2);
}

.card img {
  height: 10rem;
  object-fit: cover;
}

.card-image {
  width: 100%;
  opacity: 0.8;
}
.card-image:hover {
  opacity: 1;
}

.title {
  text-align: center;
  color: rgb(38, 45, 68);
  font-weight: 500;
  font-size: 0.8rem;
  margin: 0.5rem;
}

.investement-title {
}

.fields-ul {
  padding: 0 1.5rem;
  margin: 0.5rem 0;
}

.fields-ul li {
  font-size: 0.7rem;
  /* list-style-type: none; */
  font-weight: 350;
  padding: 0.2rem 0;
}

@media only screen and (max-width: 1200px) {
  .card {
    width: 32%;
  }
}

@media only screen and (max-width: 400px) {
  .card {
    width: 48%;
  }
}
