.contacts {
  display: flex;
  justify-content: space-around;
  height: 60vh;
  padding: 2em 1em;
}

.contacts__picture {
  background-image: url("../../resources/assets/contact/notebook.jpg");
  background-size: cover;
  background-position: center;
  width: 45%;
}

.contacts__form {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 45%;
}

.contacts__input {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  padding: 0.5rem;
  border: 1px solid rgb(167, 167, 167);
  border-radius: 2px;
}

.contacts__input:focus {
  background-color: rgb(245, 245, 245);
  outline: 0;
}

.contacts__text-area {
  height: 20%;
}

.contacts__form p {
  font-size: 0.8rem;
}

.contacts__form h4 {
  font-family: "Times New Roman", Times, serif;
  color: #caab88;
  font-size: 1.5rem;
  margin: 0;
}

.contacts__button {
  height: 2em;
  border: 1px solid rgb(182, 145, 91);
  background-color: white;
  color: rgb(182, 145, 91);
  cursor: pointer;
  font-weight: 600;
}

.contacts__button:hover {
  background-color: rgb(182, 145, 91);
  color: rgb(255, 255, 255);
}

/*Message sent*/
.contacts__message-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: center;
  align-items: center;
}

.contacts__submit-message {
  text-align: center;
  color: rgb(134, 134, 134);
  font-weight: 400;
  line-height: 1.2rem;
}
.contacts__voltar-button {
  color: white;
  text-decoration: none;
  background-color: rgb(182, 145, 91);
  padding: 0.5rem 1rem;
  border-radius: 3px;
  font-size: 0.8rem;
}

.contacts__voltar-button:hover {
  background-color: rgb(161, 129, 79);
  font-weight: 600;
}

.contacts__check-icon {
  color: rgb(95, 175, 131);
}

@media only screen and (max-width: 700px) {
  .contacts {
    display: flex;
    align-items: center;
    background-color: rgb(241, 241, 241);
    height: 80vh;
  }

  .contacts__message-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 12em;
  }

  .contacts__picture {
    display: none;
  }

  .contacts__form {
    text-align: center;
    height: 100%;
    width: 100%;
    margin: 0;
  }

  .contacts__input {
    height: 2em;
  }
  .contacts__text-area {
    height: 20%;
  }
  

  .contacts__button {
    height: 3em;
  }

  /*Message sent*/
  .contacts__message-container {
    line-height: 1.2em;
  }
}
